import "react-app-polyfill/stable"
import React from "react"
import ReactDOM from "react-dom"
import { BrowserRouter as Router, Routes, Route } from "react-router-dom"
import "semantic-ui-css/semantic.min.css"
import Home from "./pages/Home/Home"
import { HistoryContextProvider } from "./utils/HistoryContext"
import * as serviceWorker from "./utils/serviceWorker"
import { config } from "./utils/routesConfig"
import "./index.css"
import BaseLayout from "./BaseLayout/BaseLayout"
import Page from "./pages/Page/Page"

export function App(): JSX.Element {
  return (
    <HistoryContextProvider>
      <>
        <Router>
          <BaseLayout>
            <Routes>
              <Route path={config.routes.home} element={<Home />} />
              <Route path={config.routes.page} element={<Page />} />
            </Routes>
          </BaseLayout>
        </Router>
      </>
    </HistoryContextProvider>
  )
}

ReactDOM.render(<App />, document.getElementById("root"))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
