import { CoinItem, HeaderQueryRes, JobHistoryPost } from "./types"
import { gql, request } from "graphql-request"

const baseUrl = "https://api.carolagosch.com/graphql"
const baseImageUrl = "https://api.carolagosch.com/"

export async function fetchFrontPageQuery(): Promise<CoinItem> {
  const query = gql`
    {
      pageBy(uri: "/") {
        id
        content
        title
        uri
        featuredImageId
        featuredImageDatabaseId
        featuredImage {
          node {
            sourceUrl
          }
        }
        postCarousellCategory {
          postCarousellCategory: postcarousellcategory
        }
      }
    }
  `
  const response = await request(baseUrl, query)
  return response?.pageBy
}

// const getAllPagesQuery = gql`
//   {
//     pages {
//       nodes {
//         title
//         uri
//         content
//       }
//     }
//   }
// `

export async function fetchHeaderPages(): Promise<HeaderQueryRes[]> {
  const query = gql`
    {
      pages(where: { language: DE }) {
        nodes {
          title
          uri
          showPageWhere {
            showpagewhere
            lefttorightorder
          }
        }
      }
    }
  `
  const response = await request(baseUrl, query)
  return response?.pages?.nodes
}

export async function fetchPageQuery(uri: string): Promise<CoinItem> {
  const query = gql`
    {
      pageBy(uri: "${uri}") {
        id
        content(format: RENDERED)
        title
        uri
        featuredImageId
        featuredImageDatabaseId
        featuredImage{
          node{
            sourceUrl
          }
        }
         postCarousellCategory {
          postCarousellCategory : postcarousellcategory 
        }
      }
    }
  `
  const response = await request(baseUrl, query)
  return response?.pageBy
}

export async function fetchJobHistoryPosts(tags = "jobHistory"): Promise<JobHistoryPost[]> {
  const query = gql`
    {
      posts(where: { categoryName: "${tags}" }) {
        nodes {
          title
          history {
            description
            fieldGroupName
            from
            to
            lefttorightorder
          }
          featuredImage {
            node {
              sourceUrl
            }
          }
        }
      }
    }
  `
  const response = await request(baseUrl, query)
  return response?.posts?.nodes
}

export function fullImageUrl(uri: string): string {
  return baseImageUrl + uri
}
